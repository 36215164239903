import { useBMS } from "../../../../Context/BMS"
import Dialog, { DialogContent, DialogHeader, DialogBody, DialogFooter, DialogTitle } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import { useEffect, useState } from "react";
import axios from "axios";
import { OrderedMap, List } from "immutable";
import ServiceBus from 'Services/ServiceBus';
import { toast } from 'react-toastify';
import StatusListPanel from "../../../Logs/StatusListPanel";
import ModuleTypes from "../../../../../Configuration/ModuleTypes";
import { useCustomerSettings } from "../../../../../Context/CustomerSettings";

export default function AreaMenuStatusLogDialog({ show, onClose, selectedarea, currentpath }) {

    console.log("Show in dialog", show);
    console.log("selectedarea in dialog", selectedarea);

    return <Dialog className="md:max-w-[80vw]" opened={!!show} onClose={onClose}>
        <AreaMenuStatusLogPanel  
            onClose={onClose} 
            currentpath={currentpath}
            selectedarea={selectedarea} />
    </Dialog>
}

function AreaMenuStatusLogPanel({ onClose, selectedarea, currentpath }) {
    const { customer } = useBMS();
    const {customerSettings} = useCustomerSettings();
    const { site } = useBMS();
    const { currentUser:currentuser } = useBMS();

    const [pointList, setPointList] = useState([-1]);
    const [pointsToPoll, setPointsToPoll] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);


    const onReleasePoint = (point, value) => {
        setSelectedPoint(point.m_pointAddr);
        sendReleaseRequest({ point, value, subsystemNumber: point.m_pointAddr[0], customer, currentuser: currentuser });
    } 

    useEffect(() => {
        if(selectedPoint) {
            const processMesssageHandler = (message) => {
                if (selectedPoint != null) {
                    setPointList((pointList) => {
                        let newpointlist = pointList.filter((point) => {
                            return (point.m_pointAddr.join('-') != selectedPoint.join('-'))
                        })
                        return newpointlist
                    });
                }
                if (message.m_errorNumber != 0) {
                    toast.success('Error commanding point. Error :' + message.m_errorNumber, { autoClose: 2000 });
                    return;
                }
                toast.success('Point successfully commanded', { autoClose: 2000 });
            }
            ServiceBus.on('301-11', processMesssageHandler);
            return () => {
                ServiceBus.off('301-11', processMesssageHandler);
            }
        }
    }, [selectedPoint]);

    useEffect(() => {
        
        const getPointsOverrideHandler = (msg) => {
            setPointList(() => msg.m_pointsInformation);
        }       

        if(pointsToPoll && pointsToPoll.size > 0) {
            let filters = (customerSettings.get('statusLogFilters') || List()).toJS();
            console.log("Filters", filters);
            ServiceBus.on('27-151', getPointsOverrideHandler);
            let jsPointsToPoll = pointsToPoll.toJS();
            let pointsToPollArrayAddresses = Object.keys(jsPointsToPoll).map(x => x.split('-').map(y => Number(y)));

            let subsystemGroupedPoints = pointsToPollArrayAddresses.reduce((col, v) => {
                let subsystemNumber = v[0];
                if(!col[subsystemNumber]) {
                    col[subsystemNumber] = [];
                }
                col[subsystemNumber].push(v);
                return col;
            }, {})

            for(let subsystemNumber in subsystemGroupedPoints) {
                requestPointsInOverride({ 
                    customer, 
                    subsystemNumber, 
                    pointAddresses: subsystemGroupedPoints[subsystemNumber],
                    exceptions: filters
                });
            }

            return () => {
                ServiceBus.off('27-151', getPointsOverrideHandler);
            }
        }

        


    }, [pointsToPoll, customerSettings.get('statusLogFilters')])

    useEffect(() => {
        if(selectedarea) {
            (async () => {
                // console.log("statuslog selectedarea", selectedarea);
                let selectAllPoints = selectedarea.getIn(['statuslogenabled']);
                // console.log("selectAllPoints", selectAllPoints);
                let points = await getPointsFromAreaTree({ customer, site, area: selectedarea, currentpath, selectAllPoints });
                // console.log("points to watch", points);
                setPointsToPoll(points);
            })()
        }
    }, [selectedarea]);

    return <DialogContent className="md:w-full md:min-w-[80vw]">
        <DialogHeader className="items-center">
            <DialogTitle>Point Status Log</DialogTitle>
        </DialogHeader>
        <DialogBody>
            <div className="px-3">
                <StatusListPanel pointList={pointList} onReleasePoint={(point, value) => onReleasePoint(point, value)}/>
            </div>
        </DialogBody>
        <DialogFooter>
            <Button role="secondary" onClick={() => onClose()}>Cancel</Button>
        </DialogFooter>
    </DialogContent>
}

function requestPointsInOverride({customer, subsystemNumber, pointAddresses, exceptions = []}) {
    let request = {
        type: 'GENERIC',
        m_level: 150,
        trackMessage: true,
        m_guiPoll: 0,
        m_communicNum: 27,  
        m_subsystemNumber: subsystemNumber,
        target: 'signalr',
        databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
    }

    let comm = {
        m_informationType: 1,
        m_devices: [],
        m_points: pointAddresses,
        m_exceptionStrings: exceptions
    }

    ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
}


function sendReleaseRequest({ point, value, subsystemNumber, customer, currentuser }) {
    let request = {
        type: 'GENERIC',
        m_level: 10,
        trackMessage: true,
        m_guiPoll: 0,
        m_communicNum: 301,  
        m_subsystemNumber: subsystemNumber,
        target: 'signalr',
        databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
    }

    let comm = {
        m_pointAddr: point.m_pointAddr.map((value) => { return parseInt(value); }),
        m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
        m_typeOfCommand: 3,
        m_priority: 8,
        m_value: value,
        m_engUnitGuarded: 0,
        m_oldValue: value
    }

    ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
}

const isDigitalPoint = (pointAddress) => {
    let moduleIndex = pointAddress[1];
    let module = ModuleTypes[moduleIndex];
    if(module) {
        let pointType = module.pointtypes[pointAddress[2]];
        return pointType?.control == 'Digital';
    }
    return false;

}

const isAnalogPoint = (pointAddress) => {
    let moduleIndex = pointAddress[1];
    let module = ModuleTypes[moduleIndex];
    if(module) {
        let pointType = module.pointtypes[pointAddress[2]];
        return pointType?.control == 'Analog';
    }
    return false;
}

async function getPointsFromAreaTree({ customer, site, area, currentpath, selectAllPoints }) {
    let basePath = createBaseDirectory(customer, site);
    let points = OrderedMap();
    let areaUrlName = area.get('name').split(' ').join('-').toLowerCase();

    let startPath = createAreaPath({ customer, site, area, currentpath });
    
    let [areaError, areaData] = await catchError(axios.get(startPath));

    if(areaError) {
        console.log("Error getting area data", areaError);
        
    } else {
        
        let layers = areaData?.data || [];
        // console.log("layers", layers);
        let components = [];
        layers.forEach((layer) => {
            components.push(...(layer.nodes || []))
        })
        // console.log("components", components);
        components.forEach((component) => {
            let pointStringAddress = component?.configuration?.boundto;
            let pointAddress = pointStringAddress?.split('-').map(x => parseInt(x));
           if(pointStringAddress && selectAllPoints && (isDigitalPoint(pointAddress) || isAnalogPoint(pointAddress))) {
                points = points.set(pointStringAddress, true);
           }
            else if(pointStringAddress && component?.configuration?.overridereport) { // component?.configuration?.overridereport
                points = points.set(pointStringAddress, true);
           } 
        });
    }

    if((area.get('treenodes') || List()).size > 0) {
        let areaTreeNodesIterator = area.get('treenodes').entries();
        let areaTreeNodes = areaTreeNodesIterator.next();
        while(!areaTreeNodes.done) {
            let node = areaTreeNodes.value[1];
            points = points.merge(await getPointsFromAreaTree({ selectAllPoints, customer, site, area:node, currentpath: [...currentpath, areaUrlName] }));
            areaTreeNodes = areaTreeNodesIterator.next();
        }
    }

    return points;
}

async function catchError(promise) {
    try {
        let result =  await promise;
        return [null, result];
    } catch (error) {
        console.log("Error", error);
        return [error, null];
    }
}

function createAreaPath({ customer, site, area, currentpath}) {
    let basePath = createBaseDirectory(customer, site);
    let path = `/files/customers/${basePath}_${currentpath.join('_')}_${area.get('name').split(' ').join('-').toLowerCase()}.json`;
    return path;
}



function createBaseDirectory(customer, site) {
    return `${customer.get('name').toLowerCase().split(' ').join('-')}_${site.get('name').split(' ').join('-').toLowerCase()}`;
}

function createBaseLinkDirectory(customer, site) {
    return `/customer/${customer.get('name').toLowerCase().split(' ').join('-')}/site/${site.get('name').split(' ').join('-').toLowerCase()}`;
}