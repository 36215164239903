import React, {useState, useEffect, Fragment} from 'react';
import Immutable from 'immutable';
import StatusListPanel from './StatusListPanel';
import ServiceBus from 'Services/ServiceBus';
import StatusLogFilter from './StatusLogFilter';
import Dialog, { DialogContent, DialogHeader, DialogBody, DialogFooter, DialogTitle } from 'Controls/Dialog/Dialog';
import Button from 'Controls/Button/UserDefined';
import {getDevices} from '../Configuration/Subsystems/Actions';
import { useBMS } from "BmsView/Context/BMS";
import { toast } from 'react-toastify';

function StatusLogDialog({ show, onClose, ...props }) {
    return <Dialog className="md:max-w-[80vw]" opened={!!show} onClose={onClose}>
        <StatusLogDialogPanel show={show} onClose={onClose} {...props} />
    </Dialog>
}

function StatusLogDialogPanel({show, customer, onClose}) {
    const [subsystems, setSubsystems] = useState([]);
    const [selectedSubsystem, setSubsystem] = useState(-1);
    const [controllers, setControllerList] = useState(Immutable.List());
    const [type, setType] = useState("override");
    const [selectedController, setSelectedController] = useState(undefined);
    const [pointList, setPointList] = useState([-1]);
    const { currentUser:currentuser } = useBMS();
    const [selectedpoint, setSelectedPoint] = useState(null);

    useEffect(() => {
        if(customer && customer.get('subsystems')) {
            setSubsystems(customer.get('subsystems').filter((subsystem) => {
                return subsystem.get('type') == 9;
            }));
        }   
    }, []);

    useEffect(() => {   
        if(subsystems.size > 0) {
            setSubsystem(subsystems.get(0));
            getControllerList();           
        }           
    }, [subsystems]);

    const processMessageHandler = (message) => {
        if (selectedpoint != null) {
            let newpointlist = pointList.filter((point) => {
                return (point.m_pointAddr.join('-') != selectedpoint.join('-'))
            })

            setPointList(newpointlist);
        }
        
        if (message.m_errorNumber != 0) {
            toast.success('Error commanding point. Error :' + message.m_errorNumber, { autoClose: 2000 });
            return;
        }
        toast.success('Point successfully commanded', { autoClose: 2000 });
    }

    useEffect(() => {
        if(show) {
            const handler = getBacnetPointsInOverrideHandler;
            ServiceBus.on('27-151', handler);
            const messagehandler = processMessageHandler;
            ServiceBus.on('301-11', messagehandler);
            return () => {
                ServiceBus.off('27-151', handler);
                ServiceBus.off('301-11', messagehandler);
            }
        }
    }, [show, pointList, selectedpoint])

    useEffect(() => {
        if (customer && show && selectedController) getBacnetPointsInOverride();
    }, [show, selectedController]);


    const getControllerList = () => {
        getDevices(`${customer.get('name').toLowerCase().replace(/ /g, "")}`,
          `${customer.get('ipaddress').toLowerCase().replace(/ /g, "")}`, 'bacnetdevices').then((data) => {           
              setControllerList(Immutable.fromJS(data.map((controller) => {return controller}   
              )))
            });
    }

    const getBacnetPointsInOverride = () => {  
        let request = {
            type: 'GENERIC',
            m_level: 150,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 27,  
            m_subsystemNumber: selectedSubsystem.get('number'),
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        }

        let comm = {
            m_informationType: 0,
            m_devices: [selectedController],
            m_points: [[]],
            m_exceptionStrings: []
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const onReleasePoint = (point, value) => { 
        setSelectedPoint(point.m_pointAddr);
    
        let request = {
            type: 'GENERIC',
            m_level: 10,
            trackMessage: true,
            m_guiPoll: 0,
            m_communicNum: 301,  
            m_subsystemNumber: selectedSubsystem.get('number'),
            target: 'signalr',
            databaseName: `${customer.get('name').toLowerCase().replace(/ /g, "")}`
        } 

        let comm = {
            m_pointAddr: point.m_pointAddr.map((value) => { return parseInt(value); }),
            m_whoCommanded: `${currentuser.get('firstname')} ${currentuser.get('lastname') ? currentuser.get('lastname') : ""}`,
            m_typeOfCommand: 3,
            m_priority: 8,
            m_value: value,
            m_engUnitGuarded: 0,
            m_oldValue: value
        }

        ServiceBus.send('WEB_MESSAGE_REQ', request, comm);
    }

    const getBacnetPointsInOverrideHandler = (msg) => {
        console.log(' getBacnetPointsInOverrideHandler :',pointList);
        setPointList(msg.m_pointsInformation);
    }
        
    const subsystemNumberChanged = (number) => {  
        let subsystem = parseInt(number);

        setSubsystem(subsystem);
        if ( subsystem >= 0 ) 
            getControllerList();
    }

    const controllerNumberChanged = (number) => {
        setPointList(null);
        setSelectedController(number);
        
    }

    const clearSelection = () => {
        setSelectedController(undefined);
    }
            
    return (
        <DialogContent className="md:w-full md:min-w-[80vw]">
            <DialogHeader className="items-center">
                <DialogTitle>Point Status Log</DialogTitle>
                <div>
                    <StatusLogFilter subsystems={subsystems} logtype={type} selectedSubsystem={selectedSubsystem} setSubsystem={(value) => subsystemNumberChanged(value)} 
                        setController={(value) => controllerNumberChanged(value)} controllers={controllers} selectedController={selectedController}/>  
                </div>
            </DialogHeader>
            <DialogBody>                
                <div className="px-3">
                    <StatusListPanel pointList={pointList} onReleasePoint={(point, value) => onReleasePoint(point, value)}/>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button role="secondary" onClick={() => onClose()}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    );
}

export default StatusLogDialog;
